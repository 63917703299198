import React, { useState, useEffect } from "react";
import { Outlet } from "react-router";
import Sidebar from "./Sidebar";
import TopNav from "./TopNav";

function getAuthInfo() {
  return new Promise((resolve, reject) => {
    fetch(`/auth-info-api`)
      .then((response) => response.json())
      .then((recipes) => {
        resolve(recipes);
      })
      .catch(reject);
  });
}

function Layout(props) {
  const [navCollapsed, setNavCollapsed] = useState(false);
  const navToggle = () => {
    setNavCollapsed(!navCollapsed);
  };

  const [username, setUsername] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [sessionID, setSessionID] = useState("");

  async function fetchAuthInfo() {
    const authInfo = await getAuthInfo();
    setUsername(authInfo.displayName);
    setApiKey(authInfo.api_key);
    setSessionID(authInfo.session_id);
  }

  useEffect(() => {
    fetchAuthInfo();
  }, []);

  return (
    <div class="wrapper">
      <Sidebar collapsed={navCollapsed} />
      <div class="main">
        <TopNav handleToggleNav={navToggle} username={username} />
        <div class="ratio ratio-16x9">
          <Outlet context={[apiKey, sessionID]} />
        </div>
      </div>
    </div>
  );
}

export default Layout;
