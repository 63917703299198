import React from "react";

function DeEventsCalendar(props) {
  return (
    <iframe
      title="de-events-calendar"
      style={{ height: "calc(100vh - 26px - 2.5rem)" }}
      src="/de-events-calendar"
    ></iframe>
  );
}

export default DeEventsCalendar;
