import React from "react";
import { useOutletContext } from "react-router";
import RecipeDropdown from "../components/RecipeDropdown";

function VisionRecipes(props) {
  const [apiKey, sessionID] = useOutletContext();

  return (
    <div style={{ margin: "1vh 1vw 1vh 1vw" }}>
      <h1>Vision Recipe Archive Lookup</h1>
      <div>
        <RecipeDropdown apiKey={apiKey} />
      </div>
    </div>
  );
}

export default VisionRecipes;
