import React, { useState } from "react";

function TopNav(props) {
  const [showDrop, setShowDrop] = useState(false);
  const toggleDrop = () => {
    setShowDrop(!showDrop);
  };

  return (
    <nav class="navbar navbar-expand navbar-light navbar-bg">
      <a
        class="sidebar-toggle js-sidebar-toggle"
        onClick={props.handleToggleNav}
      >
        <i class="hamburger align-self-center"></i>
      </a>

      <div class="navbar-collapse collapse">
        <ul class="navbar-nav navbar-align">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle d-none d-sm-inline-block"
              data-bs-toggle="dropdown"
              onClick={toggleDrop}
            >
              <i class="align-middle" data-feather="settings"></i>
              <span class="text-dark">{props.username}</span>
            </a>
            <div
              key="dropdown-menu"
              class={
                showDrop
                  ? "dropdown-menu dropdown-menu-end show"
                  : "dropdown-menu dropdown-menu-end"
              }
              data-bs-popper="none"
            >
              <a class="dropdown-item" href="/auth-info">
                <i class="align-middle me-1" data-feather="user"></i>
                My Account
              </a>
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="https://login.microsoftonline.com/common/oauth2/logout"
              >
                <i class="align-middle me-1" data-feather="log-out"></i> Log out
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default TopNav;
