// Based on https://www.npmjs.com/package/react-select-search
// Code at https://github.com/tbleckert/react-select-search

import React, { useState, useEffect } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";
import "./RecipeDropdown.css";

// Example expected json:
// const options = [
//   { value: "40001", name: "SALAD FLORIBBEAN SEATED" },
//   { value: "40002", name: "VIN PASSION FRUIT" },
//   { value: "40005", name: "PASTA MUSHROOMS WILD" },
//   { value: "40007", name: "VEG WHITE CORN AND GOAT CHEESE SOUFFLE" },
//   { value: "40009", name: "HORD VEGETABLE SPRING ROLLS / GINGER SOY VIN" },
// ];

// function getOptions(query) {
//   return new Promise((resolve, reject) => {
//     fetch(`https://app.ajoywallace.com/vision/recipes`)
//       .then((response) => response.json())
//       .then((recipes) => {
//         resolve(
//           Object.entries(recipes).map((recipe) => {
//             return { value: recipe[0], name: recipe[1] ? recipe[1] : "" };
//           })
//         );
//       })
//       .catch(reject);
//   });
// }

function getOptions(apiKey) {
  return new Promise((resolve, reject) => {
    fetch(`/vision/recipes?api_key=` + apiKey)
      .then((response) => response.json())
      .then((recipes) => {
        resolve(recipes);
      })
      .catch(reject);
  });
}

function RecipeDropdown(props) {
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [servings, setServings] = useState(100);

  async function fetchOptions(apiKey) {
    const options = await getOptions(apiKey);
    setOptions(options);
  }

  useEffect(() => {
    fetchOptions(props.apiKey);
  }, [props.apiKey]);

  useEffect(() => {
    try {
      setServings(options.find((x) => x.value === selected).servings);
    } catch (error) {
      console.error(error);
      setServings(100);
    }
  }, [options, selected]);

  return (
    <div style={{ margin: "1vh 1vw 1vh 1vw" }}>
      <div>Recipe Selection:</div>
      <SelectSearch
        value={selected}
        onChange={setSelected}
        options={options}
        debounce={500}
        search={true}
        filterOptions={fuzzySearch}
        emptyMessage="No Recipe Selected"
        placeholder="Choose a Recipe"
      />
      <div>Recipe ID:</div>
      <input
        key="RecipeID"
        class="select-search__input"
        value={selected}
        onChange={(e) => {
          setSelected(e.target.value);
        }}
        style={{ width: "10vw" }}
      />
      <div>Scale for Count:</div>
      <input
        key="ScaleCount"
        class="select-search__input"
        value={servings}
        onChange={(e) => {
          setServings(e.target.value);
        }}
        style={{ width: "10vw" }}
      />
      <button
        style={{ margin: "1vh 1vw 1vh 1vw" }}
        onClick={() => {
          window.open(
            `/vision/rcard?id=${selected}&servings=${servings}&api_key=${props.apiKey}`
          );
        }}
      >
        Get Recipe PDF
      </button>
    </div>
  );
}

export default RecipeDropdown;
