import * as Icon from "react-feather";

// Find icon names at https://feathericons.com/

const SidebarItems = [
  {
    type: "header",
    name: "Calendars",
    route: "",
  },
  {
    type: "item",
    name: "Event Calendar",
    route: "/admin-events-calendar",
    icon: <Icon.Calendar />,
  },
  {
    type: "item",
    name: "Douglas Ent. Calendar",
    route: "/admin-de-events-calendar",
    icon: <Icon.Calendar />,
  },
  {
    type: "item",
    name: "Deposits Due",
    route: "/admin-deposits-calendar",
    icon: <Icon.Calendar />,
  },
  {
    type: "item",
    name: "Vehicle Schedule",
    route: "/admin-truck-calendar",
    icon: <Icon.Calendar />,
  },
  {
    type: "header",
    name: "Tools",
    route: "",
  },
  {
    type: "item",
    name: "Vision Recipe Archive",
    route: "/vision-recipes",
    icon: <Icon.Clipboard />,
  },
];

export default SidebarItems;
