import React from "react";

const NotFound = (props) => {
  return (
    <div>
      <p>Requested page not found!</p>
    </div>
  );
};

export default NotFound;
