import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import EventsCalendar from "./pages/EventsCalendar";
import DeEventsCalendar from "./pages/DeEventsCalendar";
import DepositsCalendar from "./pages/DepositsCalendar";
import VehicleSchedule from "./pages/VehicleSchedule";
import NotFound from "./pages/NotFound";
import VisionRecipes from "./pages/VisionRecipes";

function getCookie(name) {
  let cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=");
    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

async function checkSessionIsValid(sessionID) {
  const response = await fetch("/auth-check-api?sessionID=" + sessionID);
  const json = await response.json();
  if (json["allowed"] === "false") {
    console.log("Session is NOT Valid!");
    window.location.replace("/login");
  }
}

function MyRoutes() {
  // Make sure we are viewing in https
  // if (window.location.protocol !== "https:"){
  //   window.location.replace(window.location.href.replace("http:", "https:"));
  // }

  let sessionID = getCookie("sessionID");
  if (sessionID) {
    checkSessionIsValid(sessionID);
  } else {
    window.location.replace("/login");
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path="" element={<EventsCalendar />} />
          <Route path="admin-events-calendar" element={<EventsCalendar />} />
          <Route
            path="admin-de-events-calendar"
            element={<DeEventsCalendar />}
          />
          <Route
            path="admin-deposits-calendar"
            element={<DepositsCalendar />}
          />
          <Route path="admin-truck-calendar" element={<VehicleSchedule />} />
          <Route path="vision-recipes" element={<VisionRecipes />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
