import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SidebarItems from "./SidebarItems";

function Sidebar(props) {
  const sidebarLinkClassName = "sidebar-link";
  const sidebarLinkActiveStyle = {
    background: "#335154",
  };

  return (
    <>
      <nav
        id="sidebar"
        key="sidebar"
        class={
          props.collapsed
            ? "sidebar js-sidebar collapsed"
            : "sidebar js-sidebar"
        }
      >
        <div class="sidebar-content js-simplebar">
          <a class="sidebar-brand" href="/">
            <span class="align-middle">Joy Wallace Catering</span>
          </a>
          <ul class="sidebar-nav">
            {SidebarItems.map((item, index) => {
              return item.type === "header" ? (
                <li class="sidebar-header">{item.name}</li>
              ) : (
                <li class="sidebar-item">
                  <NavLink
                    key={item.name}
                    to={item.route}
                    className={sidebarLinkClassName}
                    style={({ isActive }) =>
                      isActive ? sidebarLinkActiveStyle : undefined
                    }
                  >
                    {item.icon}
                    <span className="align-middle">{item.name}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
